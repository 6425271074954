import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import Story from "../components/story";
import { checkPropertiesForNull } from "../utils/objectHelper";
import { SafeHtmlParser } from "../components/safeHtmlParser";
import uuid4 from "uuid4";

const StoriesPage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
					}
				}
				pageData: wpPage(slug: { eq: "stories" }) {
					seoFields {
						title
						metaDescription
						opengraphTitle
						opengraphDescription
						localBusinessSchema
						image {
							node {
								sourceUrl
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
										original {
											width
											height
										}
									}
								}
							}
						}
					}
					storiesPageFieldGroups {
						storiesSection {
							heading
							description
							stories {
								title
								description
							}
						}
						testimonialsSection {
							heading
							description
						}
					}
				}
			}
		`
	);

	const {
		pageData: {
			seoFields,
			storiesPageFieldGroups: { storiesSection, testimonialsSection },
		},
	} = data;

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Stories",
				item: {
					url: `${siteUrl}/stories`,
					id: `${siteUrl}/stories`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/stories`,
					title: `${seoFields?.opengraphTitle}`,
					description: `${seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${seoFields?.image?.node.sourceUrl}`,
							width: `${seoFields?.image?.node.localFile.childImageSharp.original.width}`,
							height: `${seoFields?.image?.node.localFile.childImageSharp.original.height}`,
							alt: "Holding Space Parent Stoies",
						},
					],
				}}
			/>

			{storiesSection &&
				!checkPropertiesForNull(storiesSection, ["heading"]) && (
					<section className="py-5">
						<Container>
							<Row>
								<Col>
									<h1 className="text-primary">{storiesSection?.heading}</h1>
									<SafeHtmlParser htmlContent={storiesSection?.description} />
								</Col>
							</Row>
						</Container>
					</section>
				)}
			{storiesSection.stories && storiesSection.stories.length > 0 && (
				<>
					{storiesSection.stories.map((item) => (
						<Story
							name={item?.title}
							storyDetail={<SafeHtmlParser htmlContent={item?.description} />}
							key={uuid4()}
						/>
					))}
				</>
			)}
			{testimonialsSection &&
				!checkPropertiesForNull(testimonialsSection, ["heading"]) && (
					<section className="py-5">
						<Container>
							<Row>
								<Col>
									<h1 className="text-primary">
										{testimonialsSection?.heading}
									</h1>
								</Col>
							</Row>
							<Row>
								<Col>
									<SafeHtmlParser
										htmlContent={testimonialsSection?.description}
									/>
								</Col>
							</Row>
						</Container>
					</section>
				)}
		</Layout>
	);
};
export default StoriesPage;
