import React from 'react'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

const Story = ({name, storyDetail}) => {
    return (
        <section>
                 <Container>
                    <Row>
                      <Col>
                        <h2 className="text-primary">{name}</h2>
                      </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>{storyDetail}</p>
                        </Col>
                    </Row>
                  </Container>
         </section>
    )
}

export default Story
